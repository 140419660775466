import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #000;
        overflow-x: hidden;
    }

    a:hover {
        color: #d5d7e8;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #d5d7e8;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #d5d7e8;
        font-size: 21px;        
        line-height: 1.41;
    }

    h5 {
        color: #d5d7e8;
        font-size: 24px;        
        line-height: 1.41;
        margin-bottom: 50px;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #d5d7e8;

        :hover {
            color: #d5d7e8;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .video-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
      }
      
      .video-wrapper {
        display: flex;
        flex-direction: column; /* Cambiar de fila a columna */
        gap: 20px; /* Espacio entre los videos */
      }

      .custom-link {
        text-decoration: none;
        background-color: #291c5c; /* Cambia el color de fondo */
        color: #fff;
        padding: 15px 30px; /* Aumenta el espaciado interno */
        border-radius: 10px; /* Aumenta el valor para bordes más redondeados */
        transition: background-color 0.3s, color 0.3s;
        display: inline-block; /* Cambia a mostrar como bloque en línea */
      
        /* Añade margen automático para centrar horizontalmente */
        margin: 0 auto;
      
        /* Cambia el tamaño de fuente */
        font-size: 18px; /* Aumenta o disminuye el valor según tu preferencia */
      
        /* Cambia el color de fondo y el color del texto al pasar el cursor */
        &:hover {
          background-color: rgb(255, 130, 92);
          color: #fff;
        }
      }
`;
